import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import dataset from 'airborne/bookings2/helpers/dataset';

function addHeapAttr(props, label, isAlternative) {
    if (!isAlternative) {
        return props;
    }

    return {...props, [`data-alternative-${label}`.toLowerCase()]: true};
}

function tabLink(tab, label, selectedTab, isAlternative) {
    const selected = tab === selectedTab;
    const cls = classnames({
        'hotel-card__tab--selected': selected,
    });
    const props = {
        className: cls,
        'data-tab': tab,
    };
    return (<a href="#" {...addHeapAttr(props, label, isAlternative)}>{label}</a>);
}

class Tabs extends React.Component {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        isAlternative: PropTypes.bool,
    };

    handleClick = (event) => {
        event.preventDefault();
        const {tab} = dataset(event.target);
        this.props.onChange(tab);
    }

    render() {
        const {value, isAlternative} = this.props;
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        const cls = classnames('h-item__tabs', {
            'h-item__tabs--active': Boolean(value),
        });
        return (<nav className={cls} onClick={this.handleClick}>
            {tabLink('rates', gettext('Rates'), value, isAlternative)}
            {tabLink('facilities', gettext('Facilities'), value)}
            {tabLink('policies', gettext('Policies'), value)}
            {tabLink('about', gettext('About'), value)}
            {tabLink('photos', gettext('Photos'), value)}
            {!isIE11 && tabLink('map', gettext('Map'), value)}
        </nav>);
    }
}

export default class HotelTabsHeader extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        selected: PropTypes.string,
        children: PropTypes.any,
        onSelect: PropTypes.func.isRequired,
        isAlternative: PropTypes.bool,
    };

    render() {
        const {selected, isAlternative} = this.props;

        return (<div>
            {this.props.children}
            <Tabs onChange={this.props.onSelect} value={selected} isAlternative={isAlternative} />
        </div>);
    }
}
