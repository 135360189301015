import React from 'react';
import PropTypes from 'prop-types';
import {gettext} from 'airborne/gettext';

import {providerCode} from 'midoffice/helpers/format';

export default class NoRates extends React.Component {
    static propTypes = {
        isFilterIssue: PropTypes.bool,
        clearFilters: PropTypes.func,
        warnings: PropTypes.arrayOf(
            PropTypes.shape({
                message: PropTypes.string,
            })
        ),
    };
    getMessage() {
        return (
            <strong className="highlight-red">{gettext('No results available. Please try changing dates or destination.')}</strong>
        );
    }
    getFilterMessage() {
        return (<React.Fragment>
            <p className="text-xl">
                <strong>{gettext('No results available based on your filter selections.')}</strong>
            </p>
            <p className="text-gray">
                {gettext('Please')}&nbsp;
                <a onClick={this.props.clearFilters}>{gettext('click here')}</a>
                &nbsp;{gettext('to reset filters or select another hotel.')}
            </p>
        </React.Fragment>);
    }

    render() {
        const {warnings, isFilterIssue} = this.props;
        const typeIssue = isFilterIssue ? 'filter' : 'basic';
        return (<div className="hotel-tab__message">
            {typeIssue === 'filter' ? this.getFilterMessage() : this.getMessage()}
            {Boolean(warnings && warnings.length) && (<div className="well">
                {warnings.map(({message, provider}, idx) => (<div key={idx}>
                    <strong>{idx}:{provider && providerCode(provider)}</strong>&nbsp;{message}
                </div>))}
            </div>)}
        </div>);
    }

}
