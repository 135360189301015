import React from 'react';
import {gettext} from 'airborne/gettext';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {hasHotelRates, getOrder} from 'airborne/search2/helpers/rates';
import {setRateOrder} from 'airborne/store/modules/search_hotels/actions/rates';

import Field from 'midoffice/newforms/components/Field';
import Select from 'midoffice/newforms/widgets/Select';
import Highlight from 'airborne/homepage2/Highlight';

@connect((state, props) => ({
    value: getOrder(state, props.id),
    hasRates: hasHotelRates(state, props.id),
}), {onChange: setRateOrder})
export default class RateSorters extends React.Component {
    static propTypes = {
        id: PropTypes.number,
        value: PropTypes.string,
        hasRates: PropTypes.bool,
        onChange: PropTypes.func,
    };

    handleChange = (value) => {
        const {onChange, id} = this.props;
        onChange(id, value);
    }

    render() {
        const {value, hasRates} = this.props;
        const choices = [
            ['sort_metric', 'Smart Choice'],
            ['average', gettext('Price – Low to High')],
            ['-average', gettext('Price – High to Low')],
        ];
        return (<div className="col form-group--single">
            <Highlight id="sort-rates" fancy disabled={value !== 'sort_metric' || !hasRates} placement="left" >
                <Field widget={Select} choices={choices} labelSuffix="" label={gettext('Sort Rates By')} value={value} onChange={this.handleChange} />
            </Highlight>
        </div>);
    }
}
