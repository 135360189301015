import PropTypes from 'prop-types';
import React from 'react';

import Glyphicons from 'midoffice/components/Glyphicons';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


export default class Promoline extends React.Component {
    static propTypes = {
        promo: PropTypes.array,
    };

    renderPromoTooltip(promo) {
        return (
            <Tooltip id="promo" className="hotel-card__promo-tag__toolip">
                <div className="text-left">
                    {promo.map(msg=> (<p key={msg}>{msg}</p>))}
                </div>
            </Tooltip>
        );
    }

    render() {
        const {promo} = this.props;
        if (!promo || !promo.length) {
            return null;
        }

        return (<span>
            <span className="hotel-card__promo-tag">
                <OverlayTrigger
                    placement="right"
                    overlay={this.renderPromoTooltip(promo)}>
                    <Glyphicons bsClass="glyphicons" glyph="conversation" />
                </OverlayTrigger>
            </span>
            &nbsp;
        </span>);
    }
}
