import compact from 'lodash/compact';
import gettext from 'airborne/gettext';
import PropTypes from 'prop-types';
import React from 'react';

function joinElements(list, sep) {
    return list.reduce((acc, part)=> (
        acc.length ? [...acc, sep, part] : [part]
    ), []);
}

export default class PaymentIcons extends React.Component {
    static propTypes = {
        plain: PropTypes.bool,
        paymentOptions: PropTypes.arrayOf(
            PropTypes.oneOf(['hotel_provider_pay', 'card', 'iata', 'vpa_guarantee', 'vpa_payment', 'invoice', 'evoucher_card', 'evoucher_iata', 'evoucher_vpa_guarantee'])
        ),
        cvv: PropTypes.bool,
    };

    render() {
        const {paymentOptions, cvv, plain} = this.props;

        const card = paymentOptions.includes('card');
        const iata = paymentOptions.includes('iata');
        const vpaGuarantee = paymentOptions.includes('vpa_guarantee');
        const hotelProviderPay = paymentOptions.includes('hotel_provider_pay');
        const vpaPayment = paymentOptions.includes('vpa_payment');
        const invoice = paymentOptions.includes('invoice');

        const evoucher = (
            paymentOptions.includes('evoucher_card') ||
            paymentOptions.includes('evoucher_iata') ||
            paymentOptions.includes('evoucher_vpa_guarantee')
        );
        const Wrap = plain ? 'span' : 'strong';

        return (<div>
            <Wrap>{joinElements(compact([card && <span key="card">
                {gettext('Credit Card')}
                &nbsp;
                {cvv && <span>
                    (<span className="highlight-orange">{gettext('CVC')}</span>)
                </span>}
            </span>,
            iata && gettext('IATA'),
            invoice && gettext('Invoice'),
            vpaGuarantee && gettext('VPA Guarantee'),
            vpaPayment && gettext('VPA Payment'),
            hotelProviderPay && gettext('Hotel Provider Pay'),
            evoucher && gettext('eVoucher')]), ' / ')}</Wrap>
        </div>);
    }
}
