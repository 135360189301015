import some from 'lodash/some';
import {perDiemsEnabled} from 'airborne/search2/helpers/hyatt';


/* OCONUS means Outside the contiguous part of the US.
   This is Alaska, Hawaii, and any other (not US) country. */
function isOconus(hotel) {
    const {'country_code': country, 'state_province_code': province} = hotel;

    const isUS = country === 'US';
    const isAlaska = province === 'AK';
    const isHawaii = province === 'HI';

    return !isUS || isAlaska || isHawaii;
}

export function haveOconus(hotels) {
    return Boolean(some(hotels, isOconus));
}

export function showOconusPerDiemsLink(state) {
    const {hotels: {hotels: {data: hotels}}} = state;

    return perDiemsEnabled(state) && haveOconus(hotels);
}
