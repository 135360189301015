import gettext from 'airborne/gettext';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import AnnotateTooltip from './AnnotateTooltip';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import Glyphicons from 'midoffice/components/Glyphicons';

function showToUser({provider, type}) {
    return provider && type !== 'AllRatesExcludedByRateExclusion';
}

function mergeProviders(warnings) {
    if (!warnings) {
        return null;
    }

    return Object.values(warnings
        .filter(showToUser)
        .reduce((acc, {provider, message}) => {
            acc[provider] = {
                provider,
                messages: acc[provider]
                    ? [...acc[provider].messages, message]
                    : [message],
            };
            return acc;
        }, {})
    );
}

function mapStateProps({hotels: {rateWarnings}}, {id}) {
    return {
        warnings: mergeProviders(rateWarnings[id]),
    };
}

@connect(mapStateProps)
export default class ProviderErrors extends React.Component {
    static propTypes = {
        warnings: PropTypes.arrayOf(PropTypes.shape({
            provider: PropTypes.string,
            message: PropTypes.string,
        }))
    };

    renderProvider({provider, messages}, idx) {
        return (<article className="h-item__errors__item" key={idx}>
            <header className="h-item__errors__header">{provider}:</header>
            {messages.map((text, idx) => <p className="h-item__errors__text" key={idx}>{text}</p>)}
        </article>);
    }

    render() {
        const {warnings} = this.props;
        if (!warnings || !warnings.length) {
            return null;
        }
        return (
            <div className="h-item__errors">
                <AnnotateTooltip id="provider-errors-tooltip">
                    <Dropdown align="end">
                        <Dropdown.Toggle id="provider-errors-dropdown" variant="light">
                            <Glyphicons bsClass="glyphicons" glyph="circle-exclamation-mark"/>
                        </Dropdown.Toggle>

                        <DropdownMenu>
                            {warnings.map((el, idx) => this.renderProvider(el, idx))}
                        </DropdownMenu>
                    </Dropdown>
                    {gettext('Click this icon to view warnings related to getting rates from providers')}
                </AnnotateTooltip>

            </div>
        );
    }
}
