import find from 'lodash/find';
import chunk from 'lodash/chunk';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import gettext from 'airborne/gettext';
import amenities from 'airborne/search2/amenities';
import hotelSelector, {getAlternativeHotels} from 'airborne/search2/helpers/hotelSelector';

import HotelTab from './HotelTab';

function mapStateToProps(state, {id}) {
    const hotelsData = [...hotelSelector(state), ...getAlternativeHotels(state)];
    const hotel = find(hotelsData, {id});
    return {
        'amenities': hotel && hotel.amenities,
    };
}

function detailsBlock(key, label, content) {
    return (<div className="hotel-details__item" key={key}>
        <h4>{label}</h4>
        <div className="hotel-details__content">
            {content}
        </div>
    </div>);

}

function amenitiesBlock(key, label, codes) {
    if (!codes.length) {
        return null;
    }
    const cols = chunk(codes, (codes.length + 1) / 2);
    return (<div className="hotel-details__item" key={key}>
        <h4>{label}</h4>
        <div className="hotel-details__content">
            {cols.map((halfCodes, idx)=> (
                <ul className="hotel-details__col" key={idx}>
                    {halfCodes.map((code)=> (<li key={code}>{amenities(code)}</li>))}
                </ul>
            ))}
        </div>
    </div>);
}

function hasInternet(codes) {
    return find(codes, (code)=> (code === 'INTT' || code === 'FINTT'));
}

function hasParking(codes) {
    return codes.includes('PARK');
}

function internetBlock(atProperty, inRoom) {
    const pubInternet = hasInternet(atProperty);
    const roomInternet = hasInternet(inRoom);
    if (!pubInternet && !roomInternet) {
        return null;
    }
    return detailsBlock('i', gettext('Internet'), [
        (<p key="p">{gettext('Public Areas')}: {amenities(pubInternet)}</p>),
        (<p key="r">{gettext('Rooms')}: {amenities(pubInternet)}</p>),
    ]);
}

function parkingBlock(value) {
    return value && (detailsBlock('p', gettext('Parking'), gettext('Parking')));
}

@connect(mapStateToProps)
export default class Facilities extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        amenities: PropTypes.shape({
            'property_amenities': PropTypes.array.isRequired,
            'room_amenities': PropTypes.array.isRequired,
        }),
    };

    render() {
        const {
            id,
            amenities: {
                'property_amenities': atProperty,
                'room_amenities': inRoom,
            }
        } = this.props;
        return (<HotelTab id={id} title={gettext('Facilities')}>
            <div className="hotel-details">
                {amenitiesBlock('g', gettext('General'), atProperty)}
                {amenitiesBlock('r', gettext('Rooms'), inRoom)}
                {internetBlock(atProperty, inRoom)}
                {parkingBlock(hasParking(atProperty) || hasParking(inRoom))}
            </div>
        </HotelTab>);
    }
}
