import air, {handleApiError} from './air';
import {searchForm} from 'airborne/homepage2/helpers/search';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';

function airDetails(hotelId, state) {
    const URL = '/hotels/get_detailed_info/';
    const {profile} = searchForm(state, 0);

    const params = {
        'configuration_id': getSelectedConfiguration(state) || profile.company,
        'hotel_id': hotelId,
    };
    return air('GET', URL, params);
}

function getDetails(id) {
    return function getDetailsD(dispatch, getState) {
        dispatch({type: 'HOTEL_DETAILS_LOADING', id});

        return airDetails(id, getState())
            .then(({'hotels_data': data})=> {
                dispatch({type: 'HOTEL_DETAILS_LOADED', id, data});
            });
    };
}

export default function getHotelDetails(id) {
    return function getHotelDetailsD(dispatch) {
        return dispatch(getDetails(id))
            .catch((response)=> (
                handleApiError(response),
                dispatch({type: 'HOTEL_DETAILS_ABORT', id})
            ))
            .catch(()=> {
                dispatch({type: 'HOTEL_DETAILS_FAIL', id});
            });
    };
}
