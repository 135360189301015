import React from 'react';
import PropTypes from 'prop-types';


function photoUrl(photo) {
    return `${photo}210x144.jpg`;
}


export default class HotelPhoto extends React.Component {
    static propTypes = {
        photo: PropTypes.string.isRequired,
    };

    render() {
        const {photo} = this.props;
        const style = {
            backgroundImage: `url(${photoUrl(photo)})`,
        };

        return (<div className="h-item__photo" style={style} />);
    }
}
