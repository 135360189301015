import React from 'react';
import PropTypes from 'prop-types';

import Loader from './Loader';
import Slideshow from './Slideshow';
import gettext from 'airborne/gettext';
import classnames from 'classnames';

const AVAIL_INTERVAL = 5000;

function availMessages() {
    return [
        gettext('Searching rates and availability…'),
        gettext('Searching additional rates and availability…'),
        gettext('Checking additional sources…'),
        gettext('It\'s taking slightly longer than expected…'),
        gettext('Almost there…'),
        gettext('Give it a couple more seconds…'),
        gettext('Almost there…'),
        gettext('We appreciate your patience…'),
    ];
}

function selectMessage(hotels, avail) {
    if (hotels) { return gettext('Loading hotels…'); }
    if (avail) {
        return (<Slideshow messages={availMessages()} interval={AVAIL_INTERVAL} />);
    }
    return gettext('Loading…');
}


export class LoaderSection extends React.Component {
    static propTypes = {
        hotels: PropTypes.bool.isRequired,
        avail: PropTypes.bool.isRequired,
        children: PropTypes.any,
    };

    render() {
        const {hotels, avail, children} = this.props;

        return (
            <div className="section-wrapper">
                <div className="col">
                    <Loader />
                </div>
                <div className="col">
                    {selectMessage(hotels, avail)}
                </div>
                {children}
            </div>
        );
    }
}

export class FloatLoader extends React.Component {
    static propTypes = {
        hotels: PropTypes.bool.isRequired,
        avail: PropTypes.bool.isRequired,
        showLoader: PropTypes.bool,
    };

    render() {
        const {hotels, avail, showLoader} = this.props;
        const className = classnames('float-preloader', {
            'in': showLoader,
            'out': !showLoader,
        });

        return (
            <div className={className}>
                <div className="float-preloader__bar">
                    <div className="float-preloader__bar__track" />
                </div>
                <div>
                    <strong>{gettext('Please stand by.')}</strong>
                </div>
                {showLoader && selectMessage(hotels, avail)}
            </div>
        );
    }
}
