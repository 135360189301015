import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import gettext from 'airborne/gettext';
import Loader from 'airborne/search2/Loader';

import HotelTab from './HotelTab';

import getHotelDetails from 'airborne/store/modules/search_hotels/actions/hotelDetails';

class PolicyItem extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        children: PropTypes.any,
    };

    render() {
        const {title, children} = this.props;
        return (<div className="hotel-details__item">
            <h4>{title}</h4>
            <div className="hotel-details__content">
                {children}
            </div>
        </div>);
    }
}

function mapStateToProps({hotels}, {id}) {
    const {loading, data} = hotels.hotelDetails;
    const hotel = data[id];
    if (!hotel) {
        return {known: false, loading: Boolean(loading[id])};
    }
    return {
        loading: Boolean(loading[id]),
        known: true,
        checkin: hotel['checkin_time'],
        checkout: hotel['checkout_time'],
        policy: hotel.policy,
    };
}


@connect(mapStateToProps, {getHotelDetails})
export default class Policies extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        loading: PropTypes.bool,
        known: PropTypes.bool,
        checkin: PropTypes.string,
        checkout: PropTypes.string,
        policy: PropTypes.string,
        getHotelDetails: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {id, known} = this.props;
        if (!known) {
            this.props.getHotelDetails(id);
        }
    }

    render() {
        const {id, loading, checkin, checkout, policy} = this.props;
        return (<HotelTab id={id} title={gettext('Policies')}>
            <div className="hotel-details">
                {loading && (<Loader top />)}
                {checkin && (<PolicyItem title={gettext('Check-in')}>
                    <p>{gettext('Check-in time starts at {time}', {time: checkin})}</p>
                </PolicyItem>)}

                {checkout && (<PolicyItem title={gettext('Check-out')}>
                    <p>{gettext('Check-out time is {time}', {time: checkout})}</p>
                </PolicyItem>)}

                <PolicyItem title={gettext('Cancellation / Prepayment')}>
                    <p>{gettext('Cancellation and prepayment policies vary according to room type. Please check what room conditions may apply when selecting your room above.')}</p>
                </PolicyItem>

                {policy && (<hr/>)}
                {policy && (<div dangerouslySetInnerHTML={{__html: policy}} />)}

            </div>
        </HotelTab>);
    }
}
