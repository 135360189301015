import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import gettext from 'airborne/gettext';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Loader from 'airborne/search2/Loader';

import {getDetails} from 'airborne/search2/helpers/rates';
import getRateDetails from 'airborne/store/modules/search_hotels/actions/rateDetails';


function mapStateProps(state, {paidPolicy, hotelId, rateKey}) {
    const {loading, loaded, details} = getDetails(state, hotelId, rateKey);

    if (!loaded) {
        return {loading, paidPolicyKnown: Boolean(paidPolicy)};
    }

    return {
        loading,
        paidPolicy: details['paid_policy'],
        paidPolicyKnown: details['paid_policy'] === 'G' || details['paid_policy'] === 'D',
    };
}

@connect(mapStateProps, {getRateDetails})
export default class PaidPolicy extends React.Component {
    static propTypes = {
        hotelId: PropTypes.number.isRequired,
        rateKey: PropTypes.string.isRequired,
        loading: PropTypes.bool,
        paidPolicy: PropTypes.string,
        paidPolicyKnown: PropTypes.bool,
        getRateDetails: PropTypes.func.isRequired,
    };

    handleRetrieve = (event) => {
        event.preventDefault();
        const {rateKey, hotelId, paidPolicyKnown} = this.props;
        if (!paidPolicyKnown) {
            this.props.getRateDetails(hotelId, rateKey);
        }
    }

    renderPolicy(policy, known) {
        if (policy === 'G') {
            return gettext('Guarantee required');
        }
        if (policy === 'D') {
            return gettext('Deposit required');
        }
        if (known) {
            return gettext('Hotel provider did not return any payment policy details for this rate. Please refer to rate details for additional information.');
        }

        return gettext('Retrieve');
    }

    renderPolicyText(policy) {
        if (policy === 'G') {
            return (<div className="h-rate__details__item highlight-green">
                {gettext('Guarantee')}
            </div>);
        }
        if (policy === 'D') {
            return (<div className="h-rate__details__item highlight-red">
                {gettext('Deposit')}
            </div>);
        }
    }

    renderTooltip(policy, known) {
        return (
            <Tooltip id="paid policy">
                {this.renderPolicy(policy, known)}
            </Tooltip>
        );
    }

    renderButton(policy, known) {
        return (<div className="h-rate__details__wrapper" onClick={this.handleRetrieve}>
            <a href="#" className="h-rate__details__empty-item">{!policy && !known && 'Retrieve'}</a>
            {this.renderPolicyText(policy) || (known && (<div className="h-rate__details__empty-item">{gettext('N/A')}</div>))}
        </div>);
    }

    render() {
        const {paidPolicy, paidPolicyKnown, loading} = this.props;
        if (loading && !paidPolicy) {
            return (<Loader />);
        }
        return (
            <OverlayTrigger
                placement="top"
                overlay={this.renderTooltip(paidPolicy, paidPolicyKnown)} >
                {this.renderButton(paidPolicy, paidPolicyKnown, loading)}
            </OverlayTrigger>
        );
    }
}
