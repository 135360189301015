import React from 'react';


import PropTypes from 'prop-types';


function selectMessage(messages, id) {
    return messages[id % messages.length];
}

export default class Slideshow extends React.Component {
    static propTypes = {
        messages: PropTypes.arrayOf(PropTypes.string).isRequired,
        interval: PropTypes.number,
    };

    constructor(props) {
        super(props);

        this.nextMessage = this.nextMessage.bind(this);
    }

    state = {current: 0};

    componentDidMount() {
        this.interval = setInterval(this.nextMessage, this.props.interval);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    nextMessage() {
        this.setState({current: this.state.current + 1});
    }

    render() {
        return (
            <span>
                {selectMessage(this.props.messages, this.state.current)}
            </span>
        );
    }
}
