import React from 'react';
import PropTypes from 'prop-types';
import RateList from './RateList';
import Facilities from './Facilities';
import Map from './maploader';
import Policies from './Policies';
import About from './About';
import Photos from './Photos';

const TABS = {
    rates: RateList,
    facilities: Facilities,
    map: Map,
    policies: Policies,
    about: About,
    photos: Photos,
};

export default class HotelTabs extends React.Component {
    static propTypes = {
        id: PropTypes.number,
        selected: PropTypes.string,
        searchPosition: PropTypes.number,
    };

    render() {
        const {selected, id, searchPosition} = this.props;
        const Tab = TABS[selected];

        return (
            <div className="h-item__tab-area">
                <Tab id={id} searchPosition={searchPosition} />
            </div>
        );
    }
}
