import gettext from 'airborne/gettext';
import first from 'lodash/first';
import last from 'lodash/last';
import inRange from 'lodash/inRange';


const SLOTS = 7;

function getPageNumber(page, current, total) {
    if (page === 'prev') { return current === 1 ? 1 : current - 1; }
    if (page === 'next') { return current === total ? total : current + 1; }
    if (page === 'dummy') { return null; }
    return page;
}

function getLabel(page) {
    if (page === 'prev') { return gettext('« Previous'); }
    if (page === 'next') { return gettext('Next »'); }
    if (page === 'dummy') { return '...'; }

    return String(page);
}

function* pagesSequence(current) {
    yield current;

    let left = current;
    let right = current;

    let direction = true;
    while (true) {
        if (direction) { yield --left; }
        else { yield ++right; }

        direction = !direction;
    }
}

export function selectPages(current, total) {
    let pages = [];

    for (let nextPage of pagesSequence(current)) {
        if (inRange(nextPage, 1, total + 1)) {
            pages.push(nextPage);
        }
        if (pages.length >= Math.min(SLOTS, total)) { break; }
    }

    pages.sort((a, b)=> a - b);

    if (first(pages) > 1) {
        pages = pages.slice(2);
        pages = [1, 'dummy', ...pages];
    }

    if (last(pages) < total) {
        pages = pages.slice(0, pages.length - 2);
        pages = [...pages, 'dummy', total];
    }

    return ['prev', ...pages, 'next'].map((page)=> ({
        page: getPageNumber(page, current, total),
        label: getLabel(page),
    }));
}
