import React from 'react';
import PropTypes from 'prop-types';
import paginate from 'midoffice/helpers/paginate';
import Glyphicons from 'midoffice/components/Glyphicons';

import classnames from 'classnames';
import {connect} from 'react-redux';

const TAPE_SIZE = 10;
const IMAGE_TYPES = {thumb: '64x64', photo: '669x414'};


function imageUrl(url, type) {
    return `${url}${IMAGE_TYPES[type]}.jpg`;
}


class Thumb extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onClick(this.props.id);
    }

    render() {
        const {image, selected} = this.props;
        const className = classnames({
            'selected': selected,
        });
        return (
            <li className={className} onClick={this.handleClick}>
                <img src={imageUrl(image, 'thumb')} />
            </li>
        );
    }
}


@connect(function(state, {images, page, selected}) {
    images = images.map((url, id)=> ({url, id, selected: selected === id}));

    const {paged, params: {total_pages: pages}} = paginate(
        images,
        null,
        {'page_size': TAPE_SIZE, page}
    );

    return {
        images: paged,
        hasPrev: page > 1,
        hasNext: page < pages
    };
}, function(dispatch, {page, onSelect, onChangePage}) {
    return {
        onSelect,
        onNextPage: ()=> onChangePage(page + 1),
        onPrevPage: ()=> onChangePage(page - 1),
    };
})
class Tape extends React.Component {
    static propTypes = {
        images: PropTypes.array.isRequired,
        hasPrev: PropTypes.bool.isRequired,
        hasNext: PropTypes.bool.isRequired,

        onSelect: PropTypes.func.isRequired,
        onNextPage: PropTypes.func.isRequired,
        onPrevPage: PropTypes.func.isRequired,
    };

    render() {
        const {images, hasPrev, hasNext, onSelect, onNextPage, onPrevPage} = this.props;

        return (
            <div className="gallery-thumbs">
                {hasPrev && (
                    <span className="gallery-thumbs__control-left prev-page" onClick={onPrevPage}>
                        <Glyphicons bsClass="glyphicon" glyph="chevron-left" />
                    </span>
                )}
                {hasNext && (
                    <span className="gallery-thumbs__control-right next-page" onClick={onNextPage}>
                        <Glyphicons bsClass="glyphicon" glyph="chevron-right" />
                    </span>
                )}
                <ul className="gallery-thumbs__list">
                    {images.map(({url, id, selected})=> (
                        <Thumb key={`${url}`}
                            id={id}
                            selected={selected}
                            image={url}
                            onClick={onSelect} />
                    ))}
                </ul>
            </div>
        );
    }
}


export default class Gallery extends React.Component {
    static propTypes = {
        images: PropTypes.arrayOf(PropTypes.string),
    };


    constructor(props) {
        super(props);

        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);

        this.handleTapePage = this.handleTapePage.bind(this);

        this.handleReference = this.handleReference.bind(this);

        this.state = {selected: 0, tapePage: 1};
    }

    componentDidMount() {
        this.root && this.root.focus();
    }

    handlePrev(event) {
        event.preventDefault();
        this.handleSelect(this.state.selected - 1);
    }

    handleNext(event) {
        event.preventDefault();
        this.handleSelect(this.state.selected + 1);
    }

    handleTapePage(tapePage) {
        this.setState({tapePage});
    }

    handleSelect(selected) {
        this.setState({selected});
    }

    handleKeyDown(event) {
        if (event.key === 'ArrowRight') {
            this.handleNext(event);
        }

        if (event.key === 'ArrowLeft') {
            this.handlePrev(event);
        }
    }

    handleReference(root) {
        this.root = root;
    }

    renderTape() {
        const {images} = this.props;
        const {selected, tapePage} = this.state;
        return (
            <Tape images={images}
                page={tapePage}
                selected={selected}
                onChangePage={this.handleTapePage}
                onSelect={this.handleSelect} />
        );
    }

    renderSelected() {
        const {images} = this.props;
        const {selected} = this.state;

        const hasPrev = selected > 0;
        const hasNext = selected < images.length - 1;

        return (
            <div className="gallery-viewport">
                {hasPrev && (
                    <span className="prev-photo gallery-viewport__control-left" onClick={this.handlePrev}>
                        <Glyphicons bsClass="glyphicon" glyph="chevron-left" />
                    </span>
                )}
                {hasNext && (
                    <span className="next-photo gallery-viewport__control-right" onClick={this.handleNext}>
                        <Glyphicons bsClass="glyphicon" glyph="chevron-right" />
                    </span>
                )}
                <div className="gallery-viewport__photo">
                    <img src={imageUrl(images[selected], 'photo')} />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div onKeyDown={this.handleKeyDown} tabIndex={1} ref={this.handleReference} className="gallery-root">
                {this.renderTape()}
                {this.renderSelected()}
            </div>
        );
    }
}
