import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {isMultiPnr} from 'airborne/store/modules/homepage/selectors/pnrProfile';

import ProceedMultiCheckout from './ProceedMultiCheckout';


@connect(function mapStateProps(state) {
    return {
        isMulti: isMultiPnr(state),
    };
})
export default class MultiBookFooter extends React.Component {
    static propTypes = {
        isMulti: PropTypes.bool,
    };

    render() {
        const {isMulti} = this.props;
        if (!isMulti) return null;

        return (<div className="bottom-bar">
            <div className="container">
                <div className="bottom-bar__text">
                    <strong>{gettext('You are in Multi-Traveler booking mode.')}</strong>
                    <div>{gettext('Rates may differ per traveler – If non-refundable rates are selected T&C’s apply from the moment the booking is confirmed.')}</div>
                </div>

                <div className="pull-right">
                    <ProceedMultiCheckout className="bottom-bar__btn" />
                </div>
            </div>
        </div>);
    }
}
