import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import {hasAccess} from 'midoffice/helpers/permission';

function formatChains(chains) {
    return chains && chains.map(({chainName, chainCode})=> (
        `${chainCode} — ${chainName}`
    )).join(' ');
}

function formatIds(providerIds) {
    return Object.entries(providerIds)
        .map(([provider, ids])=> (`[${provider}: ${ids.join(', ')}]`))
        .join(' ');
}

export default class HotelName extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        showIds: PropTypes.bool.isRequired,
        chainInfo: PropTypes.array,
        providerIds: PropTypes.object,
        // isOnRequest: PropTypes.bool,
    };

    static defaultProps = {
        showIds: true,
        // isOnRequest: false,
    };

    renderTooltip() {
        const {id, chainInfo, providerIds} = this.props;

        return (<Tooltip id="provider_ids" placement="top">
            <span>
                {formatChains(chainInfo)}
                &nbsp;
                {hasAccess('supplier_hotel_ids:view') && providerIds && formatIds({GG: [id], ...providerIds})}
            </span>
        </Tooltip>);
    }

    renderName(props={}) {
        const {
            // isOnRequest,
            name,
        } = this.props;
        return (<span {...props}>
            {name}
            {/*
                //TODO: Feature of this icon is unclear.
                &nbsp;
                {isOnRequest && (<sup className="h-item__hotel-name__label">{gettext('on-request')}</sup>)}
            */}
        </span>);
    }

    render() {
        const {id, showIds, providerIds, chainInfo} = this.props;
        if (!showIds || (!providerIds && !(chainInfo && chainInfo.length))) {
            return this.renderName();
        }

        return (<OverlayTrigger placement="top" overlay={this.renderTooltip()}>
            {this.renderName({
                tabIndex: '-1',
                'data-chains': formatChains(chainInfo),
                'data-hotelids': providerIds && formatIds({GG: [id], ...providerIds}),
            })}
        </OverlayTrigger>);
    }
}
