import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import gettext from 'airborne/gettext';

import {format as formatPrice} from 'airborne/utils/price';


export default class Price extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        available: PropTypes.bool,
        min: PropTypes.number,
        currency: PropTypes.string,
        loading: PropTypes.bool,
        children: PropTypes.any,
    };

    state = {loaded: false, changed: false, highlight: false, min: this.props.min, loading: this.props.loading};

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = null;

        if (nextProps.min && nextProps.min !== prevState.min) {
            newState = {
                changed: true
            };
        }

        if (prevState.loading && !nextProps.loading) {
            newState = {
                ...newState,
                loaded: true
            };
        }

        if (newState && newState.changed && newState.loaded) {
            newState = {
                ...newState,
                highlight: true
            };
        }

        return newState;
    }

    render() {
        const {min, currency, available, children, className} = this.props;
        const {highlight} = this.state;
        const cls = classnames(className, {
            'fade-highlight': highlight,
        });

        return (<React.Fragment>
            <div className={cls}>
                {available
                    ? (<strong className="h-rate__price__value">{formatPrice(min, currency)}</strong>)
                    : (<strong className="highlight-red">
                        {gettext('Sold Out')}
                    </strong>)
                }
                {' '}
                {children}
            </div>
            {available && (<div className="h-item__price-note">{gettext('avg. / night')}</div>)}
        </React.Fragment>);
    }
}
