import React from 'react';
import {gettext} from 'airborne/gettext';
import Button from 'midoffice/components/Button';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getFilters} from 'airborne/search2/helpers/rates';
import {filterRates} from 'airborne/store/modules/search_hotels/actions/rates';
import isEmpty from 'lodash/isEmpty';
import amenities from 'airborne/search2/amenities';
import {FILTERABLE_AMENITIES} from 'midoffice/helpers/paginate';

const ametitiesRateTypes = FILTERABLE_AMENITIES.map((code) =>
    [code, () => amenities(code), () => gettext('Amenities:')]
);

const RATE_TYPES_WITH_LABELS /* type: [name, title, section] */ = [
    ['rate_filter', () => gettext('Guarantee Only'), () => gettext('Rate Type:')],
    ...ametitiesRateTypes,
    ['non_refundable', () => gettext('Exclude Non-Refundable'), () => gettext('Cancelation policy:')],
];

class Tag extends React.Component {
    static propTypes = {
        removeFilter: PropTypes.func,
        filter: PropTypes.array,
    }
    handleClick = () => {
        const {removeFilter, filter} = this.props;
        const [name] = filter;
        removeFilter(name);
    }
    render() {
        const [name, title, section] = this.props.filter;
        return (
            <div className="h-rates-filters__item">
                {section()} <strong>{title()}</strong>
                <Button data-name={name} bsStyle={'link'} onClick={this.handleClick} className="remove-btn">×</Button>
            </div>
        );
    }
}

@connect((state, props) => ({
    filters: getFilters(state, props.id),
}), {filterRates})
export default class RateFiltersTags extends React.Component {
    static propTypes = {
        filterRates: PropTypes.func,
        filters: PropTypes.object,
        id: PropTypes.number,
    };
    constructor(props) {
        super(props);
        const {filters: {rateFilter}} = props;
        this.state = {//eslint-disable-line immutable/no-mutation
            rateFilter: rateFilter || [],
        };
    }

    removeFilter = (name) => {
        const {filters: {rateFilter}, id, filterRates} = this.props;
        filterRates(id, {rateFilter: rateFilter.filter((item) => item !== name)});
    }

    render() {
        const {filters: {rateFilter}} = this.props;
        if (isEmpty(rateFilter)) return null;
        return (<div className="h-rates-filters">
            <div className="h-rates-filters__title">{gettext('Showing Only:')}</div>
            {RATE_TYPES_WITH_LABELS.map((filter) => {
                const [name] = filter;
                const isShow = rateFilter && rateFilter.includes(name);
                return (isShow && <Tag key={name} removeFilter={this.removeFilter} filter={filter}/>);
            })}
        </div>);
    }
}
