import compact from 'lodash/compact';
import gettext from 'airborne/gettext';

import React from 'react';
import PropTypes from 'prop-types';

import Field from 'midoffice/newforms/components/Field';
import Select from 'midoffice/newforms/widgets/Select';
import {PRICE_FIELD} from 'airborne/search2/helpers/price';

const STANDARD = [
    '-preference',
    'distance',
    PRICE_FIELD,
    '-' + PRICE_FIELD,
    'hotel_rating',
    '-hotel_rating',
    'name',
    '-name',
];

function checkCustom(order) {
    return !STANDARD.includes(order);
}

export default class AftSorters extends React.Component {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    getChoices(isCustom) {
        return compact([
            isCustom && ['custom', gettext('Custom Sorting')],
            ['-preference', gettext('Preferred')],
            ['distance', gettext('Distance')],
            ['name', gettext('Hotel Name – A to Z')],
            ['-name', gettext('Hotel Name – Z to A')],
            [PRICE_FIELD, gettext('Price – Low to High')],
            ['-' + PRICE_FIELD, gettext('Price – High to Low')],
            ['hotel_rating', gettext('Stars – 1 to 5')],
            ['-hotel_rating', gettext('Stars – 5 to 1')],
        ]);
    }
    render() {
        const {value, onChange} = this.props;
        const isCustom = checkCustom(value);
        return (<div className="section-wrapper__single-control">
            <Field
                widget={Select}
                label={gettext('Sort By')}
                choices={this.getChoices(isCustom)}
                value={isCustom ? 'custom' : value}
                onChange={onChange}
            />
        </div>);
    }
}
