import PropTypes from 'prop-types';
import React from 'react';
import gettext from 'airborne/gettext';

import AnnotateTooltip from './AnnotateTooltip';

function yesNoNA(value) {
    if (value === true) {
        return (<div className="h-rate__details__item highlight-green">
            {gettext('Commissionable')}
        </div>);
    }
    if (value === false) {
        return (<div className="h-rate__details__item highlight-red">
            {gettext('Non-commissionable')}
        </div>);
    }
    return '?';
}

function comNonCom(value) {
    if (value === true) {
        return gettext('Commissionable rate');
    }
    if (value === false) {
        return gettext('Non-commissionable rate');
    }
    return gettext('Unknown');
}

export default class Commissionable extends React.Component {
    static propTypes = {
        value: PropTypes.bool,
    };

    render() {
        const {value} = this.props;
        return (<AnnotateTooltip id="commisionable">
            {yesNoNA(value)}
            {comNonCom(value)}
        </AnnotateTooltip>);
    }
}
