import React from 'react';
import classnames from 'classnames';

import PropTypes from 'prop-types';


export default class Item extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        hotel: PropTypes.bool,
        stars: PropTypes.bool,
        distance: PropTypes.bool,
        icons: PropTypes.bool,
        rating: PropTypes.bool,
        tabs: PropTypes.bool,
        price: PropTypes.bool,
    };

    render() {
        const {hotel, stars, distance, icons, tabs, rating, price} = this.props;
        const className = classnames('h-item__col', {
            'h-item__col--hotel': hotel,
            'h-item__col--stars': stars,
            'h-item__col--distance': distance,
            'h-item__col--icons': icons,
            'h-item__col--tabs': tabs,
            'h-item__col--rating': rating,
            'h-item__col--price': price,
        });

        return (
            <div className={className}>
                {this.props.children}
            </div>
        );
    }
}
