import chunk from 'lodash/chunk';
import unzip from 'lodash/unzip';
import every from 'lodash/every';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';


import settings from 'airborne/settings';
import gettext from 'airborne/gettext';
import format from 'midoffice/helpers/format';
import {format as formatPrice} from 'airborne/utils/price';
import {getDetails} from 'airborne/search2/helpers/rates';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Loader from 'airborne/search2/Loader';

import getRateDetails from 'airborne/store/modules/search_hotels/actions/rateDetails';


function formatRate(currency, {date, rate}, idx) {
    return ([
        (<th key={idx}>{date && format.date(date, settings.WEEKDAY_DATE_FORMAT)}</th>),
        (<td key={idx}>{rate && formatPrice(rate, currency)}</td>),
    ]);
}

function padDays(days) {
    if (days.length <= 7) {
        return days;
    }

    const [{date: startDate}] = days;
    const pad = Array(startDate.day())
        .fill({date: null, rate: null});
    return [...pad, ...days];
}

class PriceTable extends React.Component {
    static propTypes = {
        currency: PropTypes.string.isRequired,
        perNight: PropTypes.arrayOf(
            PropTypes.shape({
                rate: PropTypes.number.isRequired,
                date: PropTypes.object.isRequired,
            }),
        ),
        isSame: PropTypes.bool,
    };

    renderWeek(week, idx) {
        const {currency} = this.props;
        const [dates, rates] = unzip(week.map(formatRate.bind(null, currency)));
        return [
            (<thead key={['d', idx]}>
                <tr>
                    {dates}
                </tr>
            </thead>),
            (<tbody key={['r', idx]}>
                <tr>
                    {rates}
                </tr>
            </tbody>)
        ];
    }

    renderSame() {
        return (<span>
            {gettext('Refer to rate description for nightly rate details.')}
        </span>);
    }

    render() {
        const {perNight, isSame} = this.props;
        if (!perNight || !perNight.length) {
            return null;
        }

        if (isSame) {
            return this.renderSame();
        }

        const weeks = chunk(padDays(perNight), 7);
        return (<table className="tooltip__range-price">
            {weeks.map((week, idx)=> this.renderWeek(week, idx))}
        </table>);
    }
}

function isSameRate(nights) {
    if (nights.length === 0) {
        return false;
    }

    const first = nights[0]['rate'];
    return first && every(nights, ({rate})=> (
        rate === first
    ));
}

function selectDetails(state, {hotelId, rateKey}) {
    return getDetails(state, hotelId, rateKey);
}

const mapStateProps = createSelector(
    [selectDetails],
    function ({loaded, loading, details}) {
        if (!loaded) {
            return {loading, known: false};
        }
        const {'per_night': perNight, currency} = details;
        return {
            loading,
            perNight: perNight.map(({date, rate})=> ({
                date: moment(date, 'YYYY-MM-DD'),
                rate,
            })),
            isSame: isSameRate(perNight),
            known: true,
            currency,
        };
    }
);


@connect(mapStateProps, {getRateDetails})
export default class PernightTooltip extends React.Component {
    static propTypes = {
        hotelId: PropTypes.number.isRequired,
        rateKey: PropTypes.string.isRequired,
        isSame: PropTypes.bool,
        currency: PropTypes.string,
        perNight: PropTypes.arrayOf(
            PropTypes.shape({
                rate: PropTypes.number.isRequired,
                date: PropTypes.object.isRequired,
            }),
        ),
        known: PropTypes.bool,
        loading: PropTypes.bool,
        getRateDetails: PropTypes.func.isRequired,
    };

    handleRetrieve = () => {
        const {rateKey, hotelId, known, loading} = this.props;
        if (known || loading) return;
        this.props.getRateDetails(hotelId, rateKey);
    };

    renderPopover() {
        const {isSame, known, perNight, currency, loading} = this.props;
        return (
            <Tooltip id="per-night-price" className="h-rate__nights-tooltip">
                {loading
                    ? (<Loader />)
                    : (known && <PriceTable perNight={perNight} currency={currency} isSame={isSame} />)}
            </Tooltip>
        );
    }

    render() {
        return (<div className="hotel-rate__range">
            <OverlayTrigger
                trigger={['hover', 'click']}
                placement="bottom"
                overlay={this.renderPopover()} >
                <span
                    tabIndex={-1}
                    className="ico-range"
                    onMouseEnter={this.handleRetrieve}
                    onClick={this.handleRetrieve}
                />
            </OverlayTrigger>
        </div>);
    }
}
