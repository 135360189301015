import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';

import gettext from 'airborne/gettext';
import Loader from 'airborne/search2/Loader';

import Gallery from './Gallery';
import HotelTab from './HotelTab';

import getHotelDetails from 'airborne/store/modules/search_hotels/actions/hotelDetails';


function mapStateToProps({hotels}, {id}) {
    const {loading} = hotels.hotelDetails;
    const hotel = hotels.hotelDetails.data[id];
    if (!hotel) {
        return {known: false, loading: Boolean(loading[id]), images: []};
    }
    return {
        loading: Boolean(loading[id]),
        known: true,
        images: hotel.thumbnails,
    };
}


@connect(mapStateToProps, {getHotelDetails})
export default class Photos extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        known: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        images: PropTypes.arrayOf(PropTypes.string),
        getHotelDetails: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {id, known, getHotelDetails} = this.props;
        if (!known) {
            getHotelDetails(id);
        }
    }

    renderEmpty() {
        return gettext('No photos available for this hotel.');
    }

    renderGallery(images) {
        return [
            <Gallery images={images} />,
            <p className="gallery__disclaimer">
                {gettext('It is the responsibility of the hotel chain and/or the individual property to ensure the accuracy of the photos displayed. AgentSource Booking is not responsible for any inaccuracies in the photos. When photo updates are available from suppliers, they will be reflected in AgentSource Booking in each new release.')}
            </p>,
        ];
    }

    render() {
        const {id, loading, images} = this.props;
        const isEmpty = !images.length;

        return (
            <HotelTab id={id} title={gettext('Photos')}>
                {loading
                    ? (<Loader wrapped />)
                    : (isEmpty
                            ? this.renderEmpty()
                            : this.renderGallery(images))}
            </HotelTab>
        );
    }
}
