import React from 'react';
import PropTypes from 'prop-types';

import AnnotateTooltip from './AnnotateTooltip';

import {format as formatDistance, destinationTooltip} from 'airborne/homepage2/helpers/searchDistance';
import {getDestination} from 'airborne/store/modules/homepage/selectors/homepage';

import settings from 'airborne/settings';
import {connect} from 'react-redux';


@connect((state)=> ({
    companyLocation: Boolean(state.hotels.filters.value.locations),
    destination: getDestination(state).destination,
    distanceUnits: settings.USER.distance_units,
}))
export default class Distance extends React.Component {
    static propTypes = {
        companyLocation: PropTypes.bool,
        distance: PropTypes.number,
        direction: PropTypes.string,
        destination: PropTypes.object.isRequired,
        distanceUnits: PropTypes.oneOf(['KM', 'MI']),
    };

    render() {
        const {companyLocation, destination, distance, direction, distanceUnits} = this.props;
        if (!distance) {
            return (<span>—</span>);
        }

        return (
            <AnnotateTooltip id="distance">
                {formatDistance(distance, distanceUnits, direction)}
                {destinationTooltip(destination, companyLocation)}
            </AnnotateTooltip>
        );
    }
}
